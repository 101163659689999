import pdfLogo from "assets/img/pdfLogo.png";
import signature from "assets/img/signature.png";
import { oldEnglish } from "assets/fonts/oldEnglish";
import { poppins } from "assets/fonts/poppins";
import { poppinsBold } from "assets/fonts/poppinsBold";
import { poppinsItalic } from "assets/fonts/poppinsItalic";
import { alegreya } from "assets/fonts/alegreya";
import jsPDF from "jspdf";
import moment from "moment";
import {
  convertNumberToPesoWords,
  getFormattedContactNumber,
} from "utility/helpers";
import { format } from "date-fns";
import { uploadFile } from "services/services.general";

const DswdEndorsement = async (details) => {
  let pdf = null;
  const type = details?.allocationType?.id;

  // eslint-disable-next-line new-cap
  const doc = new jsPDF("a4");

  // Get the page width in points
  const pageWidth = doc.internal.pageSize.getWidth();
  // Get the height of the page
  const pageHeight = doc.internal.pageSize.height;

  // Calculate the middle position for the image
  const imageMiddleX = pageWidth / 2; // Center of the page

  // Calculate the topmost position with padding
  const paddingTop = 10; // Adjust the padding as needed
  const imageTopX = pageWidth / 2; // Center of the page
  const imageTopY = paddingTop;

  doc.addImage(pdfLogo, "PNG", imageTopX - 10, imageTopY, 25, 25);

  // Load the custom font

  doc.addFileToVFS("OldEnglish.ttf", oldEnglish);
  doc.addFont("OldEnglish.ttf", "OldEnglish", "normal");

  // Set the font
  doc.setFont("OldEnglish");

  // Set the font size and add text using the custom font
  doc.setFontSize(18);
  doc.text("Office of the Speaker", imageMiddleX, 42, { align: "center" });

  doc.text("House of Representatives", imageMiddleX, 48, {
    align: "center",
  });
  doc.text("19th Congress", imageMiddleX, 55, { align: "center" });

  doc.addFileToVFS("Poppins", poppins);
  doc.addFont("Poppins", "Poppins", "normal");

  // Set the font
  doc.setFont("Poppins");
  doc.setFontSize(11);

  const today = moment();

  const formattedDate = today.format("DD/MMMM/YYYY");

  doc.text(`DATE: ${formattedDate}`, 15, 80);

  doc.addFileToVFS("Poppins-Bold", poppinsBold);
  doc.addFont("Poppins-Bold", "Poppins-Bold", "bold");
  doc.setFont("Poppins-Bold", "bold");
  doc.setFontSize(13);
  doc.text("HON. REX GATCHALIAN", 15, 98);

  doc.addFileToVFS("Poppins-Italic", poppinsItalic);
  doc.addFont("Poppins-Italic", "Poppins-Italic", "italic");
  doc.setFont("Poppins-Italic", "italic");
  doc.setFontSize(11);
  doc.text("Secretary", 15, 103);

  doc.setFont("Poppins", "normal");
  doc.setFontSize(11);
  doc.text("Department of Social Welfare and Development", 15, 107);

  doc.text("Dear Secretary Gatchalian,", 15, 120);
  doc.setFont("Poppins-Italic", "italic");
  doc.text("Greetings!", 15, 130);

  doc.setFont("Poppins", "normal");

  const maxWidth = 183; // Maximum width for the text

  let text =
    // eslint-disable-next-line prefer-template
    "Respectfully endorsing the request of Representative " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    details?.recipient?.firstName?.trim() +
    " " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    details?.recipient?.lastName?.trim() +
    " of the " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    (details?.district || details?.party) +
    " for " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    details?.receivingEntity +
    " " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    (details?.province?.name || "") +
    " " +
    (details?.municipality?.name || "") +
    " amounting to " +
    convertNumberToPesoWords(details?.budget_allocation) +
    " (PHP " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    details?.budget_allocation?.toLocaleString("en-US") +
    ") under " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    details?.program +
    " of the " +
    // eslint-disable-next-line no-unsafe-optional-chaining
    details?.agency +
    ".";

  switch (type) {
    case 2:
      text =
        // eslint-disable-next-line prefer-template
        "Respectfully endorsing the request of House Speaker Ferdinand Martin Romualdez of the Leyte 1st District for " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        details?.receivingEntity +
        " " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        (details?.province?.name || "") +
        " " +
        (details?.municipality?.name || "") +
        " amounting to " +
        convertNumberToPesoWords(details?.budget_allocation) +
        " (PHP " + // eslint-disable-next-line no-unsafe-optional-chaining
        details?.budget_allocation?.toLocaleString("en-US") +
        ") for " +
        // eslint-disable-next-line prefer-template, no-unsafe-optional-chaining
        details?.allocationName +
        // eslint-disable-next-line prefer-template
        " under " +
        // eslint-disable-next-line prefer-template, no-unsafe-optional-chaining
        details?.program +
        // eslint-disable-next-line prefer-template
        " of the " +
        // eslint-disable-next-line prefer-template, no-unsafe-optional-chaining
        details?.agency +
        ". Contact information for the Alagang Tingog Center are as follows:";

      break;

    case 3:
      text =
        // eslint-disable-next-line max-len, prefer-template
        "Respectfully endorsing the request of House Speaker Ferdinand Martin Romualdez of the Leyte 1st District for " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        details?.receivingEntity +
        " " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        (details?.province?.name || "") +
        " " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        (details?.municipality?.name || "") +
        " amounting to " +
        convertNumberToPesoWords(details?.budget_allocation) +
        " (PHP " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        details?.budget_allocation?.toLocaleString("en-US") +
        ") for " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        details?.allocationContactName +
        " (" +
        getFormattedContactNumber(details?.allocationContactNumber) +
        ") under " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        details?.program +
        " of the " +
        // eslint-disable-next-line no-unsafe-optional-chaining
        details?.agency +
        ".";

      break;

    default:
      break;
  }

  doc.text(text, 15, 140, { align: "justify", maxWidth });

  if (type === 2) {
    doc.text("Tingog Partner: ", 15, pageHeight - 135);
    doc.setFont("Poppins", "bold");

    // eslint-disable-next-line prefer-template
    const partnerName =
      // eslint-disable-next-line prefer-template
      (details?.allocation?.principal?.partner?.firstName || "") +
      " " +
      (details?.allocation?.principal?.partner?.lastName || "") +
      " (" +
      (details?.allocation?.principal?.partner?.contact || "") +
      ")";

    if (details?.allocation?.principal?.partner?.contact) {
      doc.setFontSize(12);
      doc.text(partnerName, 45, pageHeight - 135);
    }

    doc.setFont("Poppins", "normal");

    doc.text("COS of Tingog Partner: ", 15, pageHeight - 130);
    doc.setFont("Poppins", "bold");

    const cosName =
      // eslint-disable-next-line prefer-template
      (details?.allocation?.principal?.cos?.firstName || "") +
      " " +
      (details?.allocation?.principal?.cos?.lastName || "") +
      " (" +
      (details?.allocation?.principal?.cos?.contact || "") +
      ")";

    if (details?.allocation?.principal?.cos?.contact) {
      doc.setFontSize(12);
      doc.text(cosName, 63, pageHeight - 130);
    }

    doc.setFont("Poppins", "normal");

    doc.text("ATC Manager: ", 15, pageHeight - 125);
    doc.setFont("Poppins", "bold");

    const atcName =
      // eslint-disable-next-line prefer-template
      (details?.allocation?.principal?.manager?.firstName || "") +
      " " +
      (details?.allocation?.principal?.manager?.lastName || "") +
      " (" +
      (details?.allocation?.principal?.manager?.contact || "") +
      ")";

    if (details?.allocation?.principal?.manager?.contact) {
      doc.setFontSize(12);
      doc.text(atcName, 45, pageHeight - 125);
    }
  }

  doc.setFont("Poppins", "normal");

  doc.text(
    "Subject to your department’s review process and validation guidelines, may we appeal for the approval of said request as this would be of great service to their constituents.",
    15,
    type === 2 ? 182 : 164,
    { align: "justify", maxWidth }
  );

  doc.text(
    "We hope this would merit your kind consideration and approval.",
    15,
    type === 2 ? 196 : 180
  );

  doc.setFont("Poppins", "italic");
  doc.setFontSize(13);

  doc.text("Damo nga salamat and mabuhay!", 15, type === 2 ? 204 : 192);

  doc.addImage(signature, "PNG", 30, pageHeight - 45, 60, 25);
  doc.setFont("Poppins", "bold");
  doc.setFontSize(13);
  doc.text("FERDINAND MARTIN G. ROMUALDEZ", 15, pageHeight - 22);

  doc.setFont("Poppins", "italic");
  doc.setFontSize(11);
  doc.text("Speaker", 15, pageHeight - 15);

  doc.addFileToVFS("Alegreya", alegreya);
  doc.addFont("Alegreya", "Alegreya", "normal");
  doc.setFont("Alegreya");
  doc.setFontSize(10);
  doc.setTextColor("##0000003D");

  doc.text(
    "Office of the Speaker, House of Representatives, Batasan Hills, Quezon City",
    imageMiddleX + 7,
    pageHeight - 3,
    { align: "center" }
  );

  const blobFile = doc.output("blob");

  await uploadFile({
    file: blobFile,
    // eslint-disable-next-line prefer-template
    fileName:
      // eslint-disable-next-line prefer-template, no-unsafe-optional-chaining
      details?.agencyAcronym +
      "-" +
      // eslint-disable-next-line no-unsafe-optional-chaining
      details?.program +
      // eslint-disable-next-line no-unsafe-optional-chaining
      "-" +
      // eslint-disable-next-line no-unsafe-optional-chaining
      details?.recipient?.firstName?.trim() +
      "_" +
      // eslint-disable-next-line no-unsafe-optional-chaining
      details?.recipient?.lastName?.trim() +
      "_" +
      format(new Date(), "yyyy-MM-dd HH_mm_ss.SSS") +
      ".pdf",
    callback: (res) => {
      pdf = res || "";
    },
  });

  return pdf;
};

export default DswdEndorsement;
